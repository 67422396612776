<script>
import {
  required,
  /*email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,*/
} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import tableData from "@/components/tablecomponent";
// import { _ } from "vue-underscore";

/**
 * Form validation component
 */
export default {
  components: {
    DatePicker,
    Multiselect,
    Layout,
    PageHeader,
    // Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "เพิ่มการคืนอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
    //
  },

  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 50);
  },

  created() {
    this.getLocalData();
    this.getMasterPartST();
    // this.getdataRT();
    this.getParts();
  },

  data() {
    return {
      submitformReturnPart: false,

      title: "เพิ่มการคืนอะไหล่",
      items: [
        {
          text: "อะไหล่",
        },
        {
          text: "คืนอะไหล่",
          href: "/parts/returnPart"
        },
        {
          text: "เพิ่มการคืนอะไหล่",
          active: true,
        },
      ],

      loading: undefined,
      options: [],
      dataTest: [],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formReturnPart: {
        branchId: "",
        pickingId: "",
        cusId: "",
        stockId: "",
        vehicleId: "",
        returnDate: "",
        note: "",
        dcPer: 0,
        vatPer: 7,
      },
      selectedPickings:"",
      rowsUser: [],
      rowParts: [],
      branchId: "",
      rowsDataService: [],
      partTwo: false,
      showOverlayFormRP: false,
      searchPickings: "",
      rowsRT:[],
      totalPriceRT:0,
      selectDataRT:[],
      amountRT:1,
      dcPerRT:0,
      dcPriceRT:0,
      vatPerRT:7,
      vatPriceRT:0,
      returnId:"",
      netPriceRT:0,
      showSubmitbutton: true,
      nameState: null,
      
      reqTotalPrice:0,
      reqDcPer:0,
      reqDcPrice:0,
      reqNetPrice:0,
      reqVatPer:0,
      reqVatPrice:0,
      reqGrandTotalPrice:0,

      optionMasterST:[],
      

      //? Data sum RT
      returnGrandTotalPrice:0,
      
      grandTotalPrice:0,

      //? Pickings Data'
      rowsPickings:[],
      
      //? Search Pickings
      searchNameCusPickings:"",
      searchPickingsCode:"",

      part:[],
      partsCode:'',
      selectedPart:[],  

      //? Tabe data selected 
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      pageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      filter: {
        pickingCode: "",
        customerFullNameTh: "",
        licensePlate: "",
        pickingDate: "",
      },

      fieldsPickings:[
        {
          key: "pickingDate",
          sortable: true,
          label: "วันที่(ใบจ่าย)",
        },
        {
          key: "pickingCode",
          sortable: true,
          label: "รหัสใบจ่าย",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        "action"
        
      ]
    };
  },
  validations: {
    formReturnPart: {
      branchId: {
        required,
      },
      cusId: {},
      vehicleId: {},
      returnDate: {
        required,
      },
      dcPer: {
        required,
      },
      vatPer: {
        required,
      },
    },
  },

  methods: { 
    showModal(option) {
      this.selectedPart = option;
      this.part = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice;
      this.part.totalPrice = option.sellPrice;
      this.part.netPrice = option.sellPrice;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
    },
    
    calculateReq(part) {
      
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.formReturnPart.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.calTotal();
    },
    calTotal() {
      let reqTotalPrice,
        reqDcPrice,
        reqNetPrice,
        reqVatPrice,
        reqGrandTotalPrice;

      reqTotalPrice = this.rowsRT.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.reqTotalPrice = reqTotalPrice.toFixed(2);
      reqDcPrice =
        parseFloat(this.reqTotalPrice) * parseFloat(this.formReturnPart.dcPer / 100);
      if (!isNaN(reqDcPrice)) {
        this.reqDcPrice = reqDcPrice.toFixed(2);
      }
      reqNetPrice =
        parseFloat(this.reqTotalPrice) - parseFloat(this.reqDcPrice);
      if (!isNaN(reqNetPrice)) {
        this.reqNetPrice = reqNetPrice.toFixed(2);
      }

      reqVatPrice = this.reqNetPrice * parseFloat(this.formReturnPart.vatPer / 100);
      if (!isNaN(reqVatPrice)) {
        this.reqVatPrice = reqVatPrice.toFixed(2);
      }

      reqGrandTotalPrice =
        parseFloat(this.reqNetPrice) + parseFloat(this.reqVatPrice);
      if (!isNaN(reqGrandTotalPrice)) {
        this.reqGrandTotalPrice = reqGrandTotalPrice.toFixed(2);
      }
    },



    getParts: function (branchId) {
      this.localBranchId = branchId;

      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part", {
          params: {
            page: 1,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchId],
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    onRowSelectedPickings(rowsPickings){

      this.selectedPickings = rowsPickings[0];
      // // // console.log(this.selectedPickings);

      this.formReturnPart.pickingId = {
        pickingId: this.selectedPickings.pickingId,
        pickingCode: this.selectedPickings.pickingCode
      };
      this.formReturnPart.branchId = {
        branchId: this.localDataBranchUser[0].branchId,
        nameTh: this.localDataBranchUser[0].nameTh
      };

      this.formReturnPart.cusId ={
        cusId: this.selectedPickings.cusId,
        nameTh: this.selectedPickings.customerNameTh,
        familyNameTh: this.selectedPickings.customerFamilyNameTh
      };
      this.formReturnPart.vehicleId ={ 
        vehicleId: this.selectedPickings.vehicleId,
        licensePlate: this.selectedPickings.licensePlate
      }
      this.formReturnPart.returnDate = this.selectedPickings.pickingDate;
      this.formReturnPart.vatPer = this.selectedPickings.vatPer;
      this.formReturnPart.dcPer = this.selectedPickings.dcPer;
      // // // // console.log('customer=>',this.formReturnPart.cusId);

      this.$refs.modalSelectPicking.hide();



    },
    popupmodalSelectPicking(){ 
      this.$refs.modalSelectPicking.show();
      this.getdataPickings();
    },

    putReturnPart(){ //? เสร็จสิ้น กลับสู้หน้าหลัก
    
      this.calTotal();
      useNetw
        .put("api/part-return/update", {
          returnId: this.returnId,
          pickingId: this.formReturnPart.pickingId.pickingId,
          cusId: this.formReturnPart.cusId.cusId,
          stockId: this.formReturnPart.stockId.stockId == undefined ? '' : this.formReturnPart.stockId.stockId,
          vehicleId:  this.formReturnPart.vehicleId.vehicleId,
          returnDate: this.formReturnPart.returnDate, 
          note: this.formReturnPart.note,

          totalPrice: this.reqTotalPrice,
          dcPer: this.reqDcPer,
          dcPrice: this.reqDcPrice,
          netPrice: this.reqNetPrice,
          vatPer:  this.formReturnPart.vatPer,
          vatPrice: this.reqVatPrice,
          grandTotalPrice: parseFloat(this.reqGrandTotalPrice),

        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          
          // this.getdataRT();
          // this.getTotalPriceRT;
          this.$router.push({ name: "returnPart" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
        });
    },

    
    postReturnPartFirst: function (part) { //? 2
      let arr = [];
      this.rowsRT.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.rowsRT.push(part);
      }

      this.calculateReq(part);
      this.calTotal();
      // this.getTotalPriceRT();

      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice * this.amountRT;
      part.netPrice;

      const formDataPart = new FormData();
      formDataPart.append("returnId", this.returnId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", this.amountRT);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.totalPrice * (this.dcPerRT/100));
      formDataPart.append("dcPer", this.dcPerRT);
      formDataPart.append("netPrice", part.netPrice);
      formDataPart.append("vatPer", this.formReturnPart.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.grandTotalPrice);

      formDataPart.append("returnTotalPrice", this.reqTotalPrice);
      formDataPart.append("returnDcPer", this.reqDcPer);
      formDataPart.append("returnDcPrice", this.reqDcPrice);
      formDataPart.append("returnNetPrice", this.reqNetPrice);
      formDataPart.append("returnVatPer", this.formReturnPart.vatPer);
      formDataPart.append("returnVatPrice", this.reqVatPrice);
      // const totalG = this.totalPriceRT + (this.totalPriceRT * (this.formReturnPart.vatPer/100));
      formDataPart.append("returnGrandTotalPrice", parseFloat(this.reqGrandTotalPrice));

      useNetw
        .post("api/part-return/part/store", formDataPart)			
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          // this.searchform.partsCode = "";
          this.$refs["modalAmout"].hide();
          // this.getdataRT();

        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.data.message
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },

    

    delPartRT(rowRT, index){
      // this.selectDataPO = rowsRC.item; 
      this.rowsRT.splice(index, 1);
      this.calTotal(this.rowsRT);
      // // // // console.log('RT ====> 0',rowRT);
      useNetw
        .delete("api/part-return/part/delete", {		
          data: {
            returnId: this.returnId,
            partId: rowRT.partId,

            returnTotalPrice: this.reqTotalPrice,
            returnDcPer: this.reqDcPer,
            returnDcPrice: this.reqDcPrice,
            returnNetPrice: this.reqNetPrice,
            returnVatPer:  this.formReturnPart.vatPer,
            returnVatPrice: this.reqVatPrice,
            returnGrandTotalPrice: parseFloat(this.reqGrandTotalPrice),
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.getdataRT();
          // this.getTotalPriceRT;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },

    ConfirmDelRT(rowsRT , index){
      // // // console.log(rowsRT[index]);
      // // // // console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการยกเลิกการคืนอะไหล่ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยิืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delPartRT(rowsRT[index] , index);
        }
      });
    },


    putRT(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice
    ){

      this.amountRT = amount;
      this.calTotal();
      useNetw
        .put("api/part-return/part/update", {
				
          returnId: this.returnId,
          partId: partId,
          amount: amount,
          pricePerOne: sellPrice,
          totalPrice: totalPrice,
          dcPer: dcPer,
          dcPrice: dcPrice,
          netPrice: netPrice,
          vatPer: vatPer,
          vatPrice: vatPrice, 
          grandTotalPrice: grandTotalPrice, 
          // pickingAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT, 
          // pickingAccruedAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,

          returnTotalPrice: this.reqTotalPrice,
          returnDcPer: this.reqDcPer,
          returnDcPrice: this.reqDcPrice,
          returnNetPrice: this.reqNetPrice,
          returnVatPer:  this.formReturnPart.vatPer,
          returnVatPrice: this.reqVatPrice,
          returnGrandTotalPrice: parseFloat(this.reqGrandTotalPrice),

        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          
          // this.getdataRT();
          this.getTotalPriceRT;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },

    getTotalPriceRT() {
      let sum = 0;
      // let grandSum = 0;

        this.rowsRT.forEach(function(item) {
        sum += item.netPrice;
        // grandSum += item.grandTotalPrice;
        });
        this.totalPriceRT = sum;
        // // // console.log('TTPX==> ', this.totalPriceRT);
        // this.totalPrice = sum;
        // this.rcptTotalPrice === 0 ? this.rcptTotalPrice = this.receipts.grandTotalPrice : this.rcptTotalPrice + this.receipts.grandTotalPrice

    },


    editRT(rowsRT){
      // // // console.log('select Data ===> ', rowsRT);
      this.selectDataRT = rowsRT;
      this.dcPerRT = rowsRT.dcPer;
      this.$refs.modalEditPartRT.show();
    },


    // getdataRT(){ 
    //   // this.ll = true;
    //   useNetw
    //     .get("api/part-return/parts", {				
    //       params: {
    //         page: 1,
    //         perPage: 100,
    //         // vin: this.vin.vin,
    //         returnId: this.returnId,
    //         // pickingCode: this.searchPickings,
    //       },
    //     })
    //     .then((response) => {
    //       // // // // console.log('Pickings ==> ', response.data.data)	
    //       this.rowsRT = response.data.data	
    //       this.getTotalPriceRT;
    //       this.calTotal();			
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           // appConfig.swal.title.Unauthorization,
    //           appConfig.swal.type.error
    //           // appConfig.swal.title.Unauthorization
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.isLoading = false;
    //     });
    // },
    getdataPickings: function() {
      this.isLoading = true;
      useNetw
        .get("api/part-return/pickings", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: this.branchId,
            // pickingCode: this.searchPickings,
          },
        })
        .then((response) => {
          // // // console.log('Pickings ==> ', response.data.data)
          this.rowsPickings = response.data.data		
          // console.log('dataPickings => ', this.rowsPickings);			
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },


    getDataVehicle: function() {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: [this.branchId],
          },
        })
        .then((response) => {
          this.rowsDataService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getMasterPartST: function() {
      this.rowParts = [];
      this.optionMasterST = [];
      this.masterST = [];
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.branchId,
          },
        })
        .then((response) => {
          // // // // console.log("Master Stock ==> " , response.data.data);
          this.optionMasterST = response.data.data;
          // // // // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    customLabelPart({ partCode, nameTh }){
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;

    },
    getRemoveSelected() {
      this.getDataCustomer();
    },
    getDataCustomer: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formReturnPart.branchId.branchId],
            nameTh: this.formReturnPart.cusId.nameTh,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
          // // // // console.log("cusData", this.rowsUser);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      //   this.formReturnPart.branchId = user.userAccessBranch[1].branchId;
      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      // // // // console.log(this.branchId);
    },

    // eslint-disable-next-line no-unused-vars
    postReturnPart: function() {
      // this.loading = true;
      const RPFormData = new FormData();

      RPFormData.append("branchId", this.formReturnPart.branchId.branchId);
      RPFormData.append("pickingId", this.formReturnPart.pickingId.pickingId);
      RPFormData.append("cusId", this.formReturnPart.cusId.cusId);
      RPFormData.append("stockId", this.formReturnPart.stockId.stockId == undefined ? '' : this.formReturnPart.stockId.stockId);
      RPFormData.append("vehicleId", this.formReturnPart.vehicleId.vehicleId);
      //== undefined ? this.formReturnPart.vehicleId.vehicleId = null : this.formReturnPart.vehicleId.vehicleId
      RPFormData.append("returnDate", this.formReturnPart.returnDate);
      RPFormData.append("note", this.formReturnPart.note);
      RPFormData.append("dcPer", this.formReturnPart.dcPer);
      RPFormData.append("vatPer", this.formReturnPart.vatPer);

      useNetw
        .post("api/part-return/store", RPFormData)
        .then((response) => {
          //   this.dealerIdPost = response.data.dealerId;
          // // // console.log("Success ! ", response.returnId);
          this.showSubmitbutton = false;
          this.returnId = response.data.returnId;

          this.$router.push({
            
            name: "edit-returnPart",
            params: {
              returnId: response.data.returnId,
            },
          });

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // // // console.log(this.dealerIdPost);
          this.loading = false;
          //   this.$router.push({ name: "returnPart" });
          this.partTwo = true;
          // this.getdataRT();
          this.calTotal();
          
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },

    formReturnPartSubmit() {
      // this.submitformDealer = true;
      this.$v.$touch();
      if (this.$v.formReturnPart.$invalid) {
        // // // console.log("false");
        this.submitformReturnPart = true;
      } else {
        this.postReturnPart();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">

            <form
              class="needs-validation"
              @submit.prevent="formReturnPartSubmit"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <!-- <h4 class="card-title">คืนอะไหล่</h4> -->
                  </div>
                  <div class="col-md-6 text-end">
                    <router-link :to="{ name: 'returnPart' }">
                      <button class="btn btn-secondary">
                        กลับสู่หน้าหลัก
                      </button>
                    </router-link>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                       เลือกจากรหัสใบจ่าย <code> ( กรณีต้องการเลือกจากในจ่าย ) </code>
                       <multiselect
                          id="roId"
                          v-model="formReturnPart.pickingId"
                          :options="rowsPickings"
                          style="ts-15"
                          placeholder="ค้นหาเลขที่ใบสั่งซ่อม"
                          label="pickingCode"
                          :show-labels="false"
                          track-by="pickingCode"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                    </div>
                    <div class="col-md-1">
                        &nbsp;<br>
                        <a class="btn btn-primary" @click="popupmodalSelectPicking">
                            <i class="uil-file-search-alt"></i>
                        </a>
                    </div>
                </div><br>
                
                
                <div class="row">
                  <div class="col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="branchId">สาขา :</label>

                      <multiselect
                        id="branchId"
                        v-model="formReturnPart.branchId"
                        :options="localDataBranchUser"
                        class=""
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.branchId.$error,
                        }"
                        label="nameTh"
                        :show-labels="false"
                        track-by="nameTh"
                      ></multiselect>
                      <div
                        v-if="
                          submitformReturnPart &&
                            $v.formReturnPart.branchId.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.branchId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-4">
                    <div class="mb-3 position-relative">
                      <code> * </code>
                      <label>ชื่อลูกค้า :</label>
                      <multiselect
                        v-model="formReturnPart.cusId"

                        :options="rowsUser"
                        :show-labels="false"
                        :custom-label="customLabel"
                        open-direction="bottom"
                        @search-change="getDataCustomer"
                        @remove="getRemoveSelected"
                        :disabled="formReturnPart.branchId == ''"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.cusId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="
                          submitformReturnPart && $v.formReturnPart.cusId.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.cusId.required"
                          >Please Selected Data.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="vatPer">คลังสินค้า :</label>
                      <multiselect
                        id="supId"
                        v-model="formReturnPart.stockId"
                        :options="optionMasterST"
                        class=""
                        label="nameTh"
                        :show-labels="false"
                        track-by="nameTh"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row">
                  <div class="col-6 col-sm-4 col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="validation">ทะเบียน :</label>
                      <multiselect
                        v-model="formReturnPart.vehicleId"
                        label="licensePlate"
                        type="search"
                        :options="rowsDataService"
                        :show-labels="false"
                        open-direction="bottom"
                        @search-change="getDataVehicle"
                        placeholder="Please Selected Data."
                        class="helo"
                        default
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.vehicleId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="
                          submitformReturnPart &&
                            $v.formReturnPart.vehicleId.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.vehicleId.required"
                          >Please Selected Data.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="Date">วันที่ :</label>
                      <!-- disabled="disabled"  -->
                      <date-picker
                        v-model="formReturnPart.returnDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.returnDate.$error,
                        }"
                      ></date-picker>
                      <div
                        v-if="
                          submitformReturnPart &&
                            $v.formReturnPart.returnDate.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.returnDate.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code> <label for="vatPer"> ภาษี(%) :</label>
                      <!-- disabled="disabled"  -->
                      <input
                        id="vatPer"
                        v-model="formReturnPart.vatPer"
                        :min="1"
                        type="number"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.vatPer.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformReturnPart &&
                            $v.formReturnPart.vatPer.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.vatPer.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code> <label for="vatPer"> ส่วนลด(%) :</label>
                      <!-- disabled="disabled"  -->
                      <input
                        id="vatPer"
                        v-model="formReturnPart.dcPer"
                        type="number"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid':
                            submitformReturnPart &&
                            $v.formReturnPart.dcPer.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformReturnPart && $v.formReturnPart.dcPer.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formReturnPart.dcPer.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //!End row -->
                <div class="row">
                  <div class="col-6 col-sm-2 col-lg-5">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label for="validation">หมายเหตุ :</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="formReturnPart.note"
                        placeholder="หมายเหตุ"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="text-end">
                  <button v-show="showSubmitbutton" type="submit" class="btn btn-success">บันทึก</button>
                </div>
              </div>
            </form>
          </div>
          <!-- //!end card -->

          <div class="row" v-if="partTwo">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  

                  <div class="row">
                    <!-- <div class="col-md-10">
                      <strong><code>* </code>รหัสใบจ่าย</strong>
                      <div class="row">
                        <div class="col-md-10">
                            <input 
                            type="text" 
                            v-model="searchPickings" 
                            class="form-control">
                        </div>

                        <div class="col-md-2">
                          <button class="btn btn-primary" @click="getdataPickings()">
                            ค้นหาด้วยใบจ่าย
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <br />

                  <div class="row"></div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <!-- <strong>---------------------------</strong> -->
                    </div>
                    <h4>
                      <strong><code>* </code>รายการอะไหล่ที่คืนแล้ว</strong>
                    </h4>
                    <br />
                  </div>
                  <br />
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">รหัสอะไหล่</th>
                            <th scope="col">ชื่ออะไหล่</th>
                            <th scope="col">ราคา/หน่วย</th>
                            <th scope="col">จำนวน</th>
                            <th scope="col" class="text-end">รวม</th>
                            <th scope="col">ส่วนลด(%)</th>
                            <th scope="col">ส่วนลดยอด</th>
                            <th scope="col" class="text-end">หลังลด</th>
                            <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                            <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody v-if="this.rowsRT.length > 0">
                          <tr v-for="(partItem, index) in rowsRT" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ partItem.partCode }}</td>
                            <td>{{ partItem.partNameTh }}</td>
                            <td class="number">
                              <input
                                v-model="partItem.pricePerOne"
                                class="form-control text-end"
                                :disabled="partItem.status == 'APV'"
                                @keyup.enter="
                                  putRT(
                                    partItem.partId,
                                    partItem.pricePerOne,
                                    partItem.amount,
                                    partItem.totalPrice,
                                    partItem.dcPer,
                                    partItem.dcPrice,
                                    partItem.netPrice,
                                    partItem.vatPer,
                                    partItem.vatPrice,
                                    partItem.grandTotalPrice
                                  )
                                "
                                @input="calculateReq(partItem)"
                                @change="calculateReq(partItem)"
                              />
                            </td>

                            <td class="Dc">
                              <input
                                v-model="partItem.amount"
                                class="form-control text-end"
                                @keyup.enter="
                                  putRT(
                                    partItem.partId,
                                    partItem.pricePerOne,
                                    partItem.amount,
                                    partItem.totalPrice,
                                    partItem.dcPer,
                                    partItem.dcPrice,
                                    partItem.netPrice,
                                    partItem.vatPer,
                                    partItem.vatPrice,
                                    partItem.grandTotalPrice
                                  )
                                "
                                @input="calculateReq(partItem)"
                                @change="calculateReq(partItem)"
                              />
                            </td>
                            <td class="text-end">{{ partItem.totalPrice }}</td>
                            <td class="Dc">
                              <input
                                v-model="partItem.dcPer"
                                class="form-control text-end"
                                @keyup.enter="
                                  putRT(
                                    partItem.partId,
                                    partItem.pricePerOne,
                                    partItem.amount,
                                    partItem.totalPrice,
                                    partItem.dcPer,
                                    partItem.dcPrice,
                                    partItem.netPrice,
                                    partItem.vatPer,
                                    partItem.vatPrice,
                                    partItem.grandTotalPrice
                                  )
                                "
                                @input="calculateReq(partItem)"
                                @change="calculateReq(partItem)"
                              />
                            </td>
                            <td class="text-end" @change="calculateReq(partItem)">
                              {{ partItem.dcPrice }}
                            </td>
                            <td class="text-end" @change="calculateReq(partItem)">
                              {{ partItem.netPrice }}
                            </td>
                            <!-- <td class="text-end" @change="calculatePo(partItem)">
                              {{ partItem.vatPer }}
                            </td>
                            <td class="text-end" @change="calculatePo(partItem)">
                              {{ partItem.vatPrice }}
                            </td> -->
                            <!-- <td class="text-end" @change="calculatePo(partItem)">
                              {{ partItem.grandTotalPrice }}
                            </td> -->
                            <td>
                              <ul class="list-inline mb-0 action">
                                <li class="list-inline-item">
                                  <a
                                    class="px-2 text-danger"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click="ConfirmDelRT(rowsRT, index)"
                                  >
                                    <i class="uil uil-trash-alt font-size-18"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <span
                        v-if="this.rowsRT.length === 0"
                        class="text-center font-size-16"
                        >ไม่พบรายการ</span
                      >
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <p class="card-title-desc">ค้นหารายการอะไหล่</p>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label for="partsCode"
                          ><code>*</code> ค้นหารหัสอะไหล่,ชื่ออะไหล่</label
                        >
                        <multiselect
                          :disabled="this.part.status == 'APV'"
                          id="partsCode"
                          v-model="partsCode"
                          :options="rowParts"
                          label="partId"
                          :custom-label="customLabelPart"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="showModal(partsCode)"
                          :loading="loading"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          @search-change="getParts"
                        >
                        </multiselect>

                        <!-- @ok="handleOk" -->
                      </div>
                    </div>

                    <div></div>
                  </div>

                  <div class="row">
                    <div class="card" v-show="partTwo">
                      <!--  -->
                      <b-overlay
                        :show="showOverlayFormRP"
                        spinner-variant="primary"
                        spinner-type="grow"
                        spinner-small
                        rounded="sm"
                      >
                        <div class="card-body">
                          <h4 class="card-title">
                            <h4>
                              <strong>สรุปยอดการคืนอะไหล่</strong>
                            </h4>
                          </h4>
                          <hr />
                          <div class="row">
                            <div class="col-md-2">
                              รายละเอียดการรับ
                            </div>
                            <div class="col-md-2">
                              <div style="display: inline-block">
                                <div class="text-end">
                                  ยอดรวมค่าอะไหล่ : <br />
                                  ส่วนลดรวม(%) : <br />
                                  ส่วนลดรวม(บาท) : <br />
                                  ภาษีรวม(%) : <br />
                                  ภาษีรวม(บาท) : <br />
                                  สุทธิ : <br />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 text-end">
                                    {{ Number(reqTotalPrice).toLocaleString() }} <br>
                                    {{formReturnPart.dcPer}}<br>
                                    <!-- {{Number(reqDcPrice).toLocaleString()}} <br> -->
                                    {{formReturnPart.vatPer}} <br>
                                    {{ Number(reqVatPrice).toLocaleString() }} <br>
                                    {{ Number(parseFloat(reqGrandTotalPrice)).toLocaleString() }} 
                            </div>
                            <div class="col-md-1 text-end">
                              บาท<br />
                              %<br />
                              บาท <br />
                              % <br />
                              บาท <br />
                              บาท
                            </div>
                            <!-- มูลค่ารวม -->
                            <div
                              class="col-5 bg-dark text-white m-0"
                              style="border-radius:10px;margin:auto;justify "
                            >
                              <div class="row align-items-center m-0">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-md-3">
                                      มูลค่ารวม :
                                    </div>
                                    <div class="col-6 font-size-24">
                                      {{ Number(parseFloat(this.reqGrandTotalPrice)).toLocaleString() }}
                                    </div>
                                    <div class="col-3">
                                      บาท
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <br /><br />
                          <div class="row">
                            <hr />
                            <div class="text-end">
                              <button class="btn btn-success" @click="putReturnPart()">
                                เสร็จสิ้น-กลับสู่หน้าหลัก
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>


    <!-- <b-modal
          title="แก้ไขการคืนอะไหล่"
          hide-footer
          size="md" 
          centered
          ref="modalEditPartRT"
        >
          <div class="row">
            <div class="col">
              <label for="amountPO">
                <b> รหัสใบจ่าย : {{ selectDataRT.returnId }} <br>
                อะไหล่ : {{selectDataRT.partCode}}  - {{selectDataRT.partNameTh}} <br>
                คงเหลือ : {{ selectDataRT.pickingAccruedAmount }} <br>
                ราคา : {{selectDataRT.pricePerOne}} บาท </b>
               :</label>
              
              <div class="row">
                <div class="col-md-3">
                  <b>จำนวน :</b>
                </div>
                <div class="col-md-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model="amountRT"
                    id="amountRT"
                  />
                </div>
                <div class="col-md-3" v-show="selectDataRT.pickingAccruedAmount < amountRT">
                  <code> !!! เกินจำนวน </code>
                </div>
              </div><br>
                <div class="row">
                  <div class="col-md-3">
                    <b> ส่วนลด(%) : </b>
                  </div>
                  <div class="col-md-3">
                    <input
                    type="number"
                    class="form-control"
                    v-model="dcPerRT"
                    style="text-align: center;"
                    />
                  </div>
                  <div class="col-md-5">
                    = ส่วนลด {{ dcPriceRT = (dcPerRT/100) * (amountRT * selectDataRT.pricePerOne )   }} บาท <br>
                    ( หักส่วนลดแล้ว = {{ netPriceRT = (amountRT * selectDataRT.pricePerOne )-((dcPerRT/100) * (amountRT * selectDataRT.pricePerOne ))}} บาท )
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                   <b>ภาษี(%) : </b> 
                  </div>
                  <div class="col-md-3">
                    <input
                      type="number"
                      class="form-control"
                      v-model="vatPerRT"
                      
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    = ภาษี {{ vatPriceRT = (((amountRT * selectDataRT.pricePerOne ) - ((dcPerRT/100) * (amountRT * 
                    selectDataRT.pricePerOne ))) * (vatPerRT/100)).toFixed(2) }} บาท <br>
                    
                    ( ยอดรวมภาษี = {{
                      
                      grandTotalPrice = (amountRT * selectDataRT.pricePerOne )-((dcPerRT/100) * (amountRT * selectDataRT.pricePerOne ))+
                      ((((amountRT * selectDataRT.pricePerOne ) - ((dcPerRT/100) * (amountRT * selectDataRT.pricePerOne ))) * (vatPerRT/100)))
                      }} บาท )
                   
                  </div>
                </div>
                
              </div>
            </div>
          
          <hr />

          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <button class="btn btn-success" @click="putRT()">
                  แก้ไข
                </button>
              </div>
            </div>
          </div>
        </b-modal> -->



        <!-- //? Modal Select Picking  -->
        <b-modal
          title="ค้นหาใบจ่าย"
          hide-footer
          size="xl" 
          centered
          ref="modalSelectPicking"
        >
        <div class="row">
          <div class="col-md-4">
            <input 
            class="form-control" 
            type="text" 
            v-model="searchPickingsCode"
            placeholder="รหัสใบจ่าย"><br>
          </div>

          <div class="col-md-4">
            <input 
            class="form-control" 
            type="text" 
            v-model="searchNameCusPickings"
            placeholder="ชื่อลูกค้า"><br>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" @click="getdataPickings()">ค้นหา</button>
          </div>
        </div>
          <div class="row">
                      <div class="table-responsive mb-0">
            <b-table
              :items="rowsPickings"
              :fields="fieldsPickings"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelectedPickings"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
            </b-table>
          </div>
            
          </div>
        </b-modal>

        <!-- //? Modal select Part -->
        <b-modal
                  ref="modalAmout"
                  title="ระบุจำนวน"
                  hide-footer
                  size="sm"
                  centered

                >
                  
                    <div class="row">
                      <div class="col">
                        <div class="mb-3 position-relative">
                          <label for="amount"><code>*</code> จำนวน :</label>
                          <span>({{ this.part.nameTh }})</span>
                          <input
                            :state="nameState"
                            id="amount"
                            v-model="amountRT"
                            type="number"
                            style="text-align: center"
                            class="form-control"
                            value="Otto"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-11">
                        <div class="mb-3 position-relative">
                          <label for="amount"><code>*</code> %ส่วนลด :</label>
                          <input
                            :state="nameState"
                            id="amount"
                            v-model="dcPerRT"
                            type="number"
                            style="text-align: center"
                            class="form-control"
                            value="Otto"
                          />
                        </div>
                      </div>
                      <div class="col-1">
                        &nbsp; <br> <br>
                        %
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="text-end">
                          <button
                            @click="postReturnPartFirst(part)"
                            class="btn btn-primary"
                          >
                            เพิ่ม
                          </button>
                        </div>
                      </div>
                    </div>
                  
                </b-modal>
                <!-- end model -->
  </Layout>
  
</template>
